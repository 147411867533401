import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.administrador;

const fetchAllAdmins = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};

const fetchOneAdmin = (adminId) => {
  return httpClient.get(`${URL}/${adminId}`).then(res => res.data);
};

const updateAdmin = (adminToEdit) => {
  return httpClient.put(`${URL}/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};

const createAdmin = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
};

const deleteAdmin = (adminId) => {
  return httpClient.delete(`${URL}/${adminId}`).then(res => res);
};

const getFechaNotificaciones = () => {
  return httpClient.get(`${URL}-notificaciones`).then(res => res.data);
}

const updateFechaNotificaciones = (fecha_notificaciones) => {
  return httpClient.put(`${URL}/notificaciones`, {fecha_notificaciones}).then(res => res.data);
}

export {
  fetchAllAdmins,
  fetchOneAdmin,
  updateAdmin,
  deleteAdmin,
  createAdmin,
  getFechaNotificaciones,
  updateFechaNotificaciones
}
