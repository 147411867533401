const DetallePedido = () => import('@/app/views/pedidos/views/DetallePedido');
const ListadoPedidos = () => import('@/app/views/pedidos/views/ListadoPedidos');
const NuevoPedido = () => import('@/app/views/pedidos/views/NuevoPedido');

const pedidosRoutes = [
  {
    path: 'pedidos',
    redirect: '/gestion/pedidos',
    name: 'Pedidos',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoPedidos,
        meta: {
          auth: true,
        }
      },
      {
        path: '/pedidos',
        name: '',
        component: NuevoPedido,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/gestion/pedidos/nuevo',
            name: 'Nuevo',
            meta: {
              auth: true
            }
          }
        ]
      },
      {
        path: '/pedidos',
        name: '',
        component: DetallePedido,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/gestion/pedidos/:id',
            name: 'Detalle',
            meta: {
              auth: true,
            }
          }
        ]
      },

    ]
  }
];

export default pedidosRoutes;
