import { httpClient } from "@/app/shared/http-service";
import { setHeader } from "@/app/shared/http-service";
import { apiConstants } from "../config/api-constants";

const URL = apiConstants.auth;
const tokenKey = 'token';

let _credentials = null;
let remember = true;

/**
 *
 * @param {string} email
 * @param {string} password
 * @return {Promise<Credentials>}
 */
const login = (email, password) => {
  return httpClient.post(`${URL}/login`, { email, password }).then(resp => {

    let response = resp.data.data;

    localStorage.setItem('token', response.access_token);
    localStorage.setItem('user_name', response.user.name);
    localStorage.setItem('user_id', response.user.id);
    localStorage.setItem('user_email', response.user.email);
    localStorage.setItem('user_rol', response.user.role);
    localStorage.setItem('login_time', new Date());
    localStorage.setItem('expires_in',response.expires_in);

    return resp.data;
  });
}

const logout = () => {
  // this.setCredentials();

  return httpClient.get(`${URL}/logout`).then(res => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    localStorage.removeItem('user_name');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_email');
    localStorage.removeItem('login_time');
    localStorage.removeItem('expires_in');

    return res.data
  })
};

const getLoggedUser = () => {
  setHeader({key: "Content-Type" , value: "application/json" });
  return httpClient.get(`${URL}/user`).then(res => res.data);
}

const refresh = () => {
  setHeader({key: "X-Requested-With" , value: "XMLHttpRequest" });
  return httpClient.get(`${URL}/refresh`).then(res => {

    localStorage.setItem("token", res.data.data.access_token);
    localStorage.setItem("login_time", new Date());

    // location.reload(); // TODO: upadte token from header
    setHeader({key: "Authorization" , value:  `Bearer ${res.data.data.access_token}` });
    return res.data
  });
}

const forgotPassword = (email) => {
  return httpClient.post(`${URL}/password-recovery/ask`, { email }).then(res => res.data);
};

const recoveryPassword = (token, password) => {
  setHeader({key: "Content-Type" , value: "application/json" });
  return httpClient.post(`${URL}/password-recovery/update`, { token, password })
    .then(res => res.data);
};

/**
 * Sets the user credentials.
 * The credentials may be persisted across sessions by setting the `remember` parameter to true.
 * Otherwise, the credentials are only persisted for the current session.
 * @param {Credentials=} credentials The user credentials.
 * @param {boolean=} remember True to remember credentials across sessions.
 */
 const setCredentials = (credentials = null, remember = false) => {
  _credentials = credentials || null;

  if (credentials) {
    const storage = remember ? localStorage : sessionStorage;
    storage.setItem(tokenKey, credentials.access_token);
  } else {
    sessionStorage.removeItem(tokenKey);
    localStorage.removeItem(tokenKey);
  }
};

/**
 * Checks is the user is authenticated.
 * @return {boolean} True if the user is authenticated.
 */
 const isAuthenticated = () => {
  return !!this.credentials;
}

/**
 * Gets the user credentials.
 * @return {Credentials} The user credentials or null if the user is not authenticated.
 */
 const credentials = () => {
  return this._credentials;
}

const getUserId = () => {
  return localStorage.getItem('user_id');
}

const getToken = () =>
{
  return sessionStorage.getItem('token');
}


export  {
  login,
  logout,
  refresh,
  forgotPassword,
  recoveryPassword,
  getLoggedUser,
  getUserId,
  getToken
};
