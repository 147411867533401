import { httpClient, setResponseType } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.log;

const fetchAllLogs = (page = null, excel = false) => {
  let to_excel = excel ? "to_excel=true" : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&${to_excel}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${to_excel}`).then(res => res.data)
  }
};

const getLogsPedido = (idPedido) => {
  return httpClient.get(`${URL}-pedido/${idPedido}`).then(res => res.data);
}

const getLogsCliente = (idCliente) => {
  return httpClient.get(`${URL}-cliente/${idCliente}`).then(res => res.data)
}

const downloadExcel = (modelo, filename) => {
  setResponseType('blob'); // arraybuffer
  return httpClient.get(`${URL}/to-excel?name=${filename}`).then(res => {
    setResponseType('json');
    return res
  });
};


export {
  fetchAllLogs,
  getLogsPedido,
  getLogsCliente,
  downloadExcel
}
