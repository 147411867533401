const NuevoFeriados = () => import('@/app/views/configuracion/feriados/views/NuevoFeriado');
const DetalleFeriados = () => import('@/app/views/configuracion/feriados/views/DetalleFeriado');
const ListadoFeriados = () => import('@/app/views/configuracion/feriados/views/ListadoFeriados');

const feriadosRoutes = [
  {
    path: 'festivos',
    redirect: '/configuracion/festivos',
    name: 'Festivos',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoFeriados,
        meta: {
          auth: true,
        }
      },

      {
        path: '/festivos',
        name: '',
        component: NuevoFeriados,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/configuracion/festivos/nuevo',
            name: 'Nuevo',
            meta: {
              auth: true,
            }
          },
        ]
      },
      {
        path: '/feriados',
        name: '',
        component: DetalleFeriados,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/configuracion/festivos/:id',
            name: 'Detalle',
            meta: {
              auth: true,
            }
          },
        ]
      },

    ]
  }
];

export default feriadosRoutes;
