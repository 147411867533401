import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment';
import { fetchAllLogs } from '@/app/shared/global/services/log-service';
import { updateFechaNotificaciones } from './app/shared/global/services/admin-service';
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: false,
  asideShow: false,
  logs: [],
  fecha_notificaciones: moment()
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  loadLogs() {
    fetchAllLogs().then((res) => {
      state.logs = res.data;
    });
  },
  verNotificaciones() {
    const now = moment();

    state.fecha_notificaciones = now;

    updateFechaNotificaciones(now.format('YYYY-MM-DD HH:mm:ss'));
  }
}

export default new Vuex.Store({
  state,
  mutations
})
