const ListadoLogs = () => import('@/app/views/configuracion/logs/views/ListadoLogs');


const logRoutes = [
  {
    path: 'logs',
    redirect: '/configuracion/logs',
    name: 'Logs',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoLogs,
        meta: {
          auth: true,
        }
      },
    ]
  }
];

export default logRoutes;
