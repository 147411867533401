const DetalleAdmin = () => import('@/app/views/configuracion/administradores/views/DetalleAdmin');
const ListadoAdmins = () => import('@/app/views/configuracion/administradores/views/ListadoAdmins');
const NuevoAdmin = () => import('@/app/views/configuracion/administradores/views/NuevoAdmin');


const adminRoutes = [
  {
    path: 'usuarios',
    redirect: '/configuracion/usuarios',
    name: 'Usuarios',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoAdmins,
        meta: {
          auth: true,
        }
      },
      {
        path: '/configuracion/usuarios',
        name: '',
        component: NuevoAdmin,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/configuracion/usuarios/nuevo',
            name: 'Nuevo',
            meta: {
              auth: true,
            }
          }
        ]
      },
      {
        path: '/configuracion/usuarios',
        name: '',
        component: DetalleAdmin,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/configuracion/usuarios/:id',
            name: 'Detalle',
            meta: {
              auth: true,
            }
          }
        ]
      }
    ]
  }
];

export default adminRoutes;
