import { ADMIN_API } from "@/config";

export const apiGlobalConstants = {
  admin: ADMIN_API,
  administrador: `${ADMIN_API}/administradores`,
  paciente: `${ADMIN_API}/pacientes`,
  feriados: `${ADMIN_API}/feriados`,
  medico: `${ADMIN_API}/medicos`,
  cliente: `${ADMIN_API}/clientes`,
  pedido: `${ADMIN_API}/pedidos`,
  pedidosRetrasados: `${ADMIN_API}/pedidos/retrasados`,
  comentario: `${ADMIN_API}/comentarios`,
  fichero: `${ADMIN_API}/ficheros`,
  log: `${ADMIN_API}/logs`,
  variable: `${ADMIN_API}/variables`,
  tarea: `${ADMIN_API}/tareas`
}
