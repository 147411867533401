const Informes = () => import('@/app/views/informes/views/Informes');

const informesRoutes = [
  {
    path: 'informes',
    redirect: '/informes',
    name: 'Informes',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: '',
        component: Informes,
        meta: {
          auth: true,
        }
      },
    ]
  }
];

export default informesRoutes;
