const DetallePaciente = () => import('@/app/views/pacientes/views/DetallePaciente');
const ListadoPacientes = () => import('@/app/views/pacientes/views/ListadoPacientes');

const pacientesRoutes = [
  {
    path: 'pacientes',
    redirect: '/gestion/pacientes',
    name: 'Pacientes',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'Listado',
        component: ListadoPacientes,
        meta: {
          auth: true,
        }
      },
      {
        path: '/pacientes',
        name: '',
        component: DetallePaciente,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/gestion/pacientes/:id',
            name: 'Detalle',
            meta: {
              auth: true,
            }
          }
        ]
      },

    ]
  }
];

export default pacientesRoutes;
