import Vue from 'vue'
import Router from 'vue-router'

import { getLoggedUser, refresh } from '@/app/views/auth/shared/services';

// Containers
const TheContainer = () => import('@/app/containers/TheContainer')

//Routes
import { clientesRoutes } from '@/app/views/clientes';
import { pedidosRoutes } from '@/app/views/pedidos';
import { authRoutes } from '@/app/views/auth';
import { pacientesRoutes } from '@/app/views/pacientes';
import { medicosRoutes } from '@/app/views/medicos';
import { informesRoutes } from '@/app/views/informes';


import { adminRoutes } from '@/app/views/configuracion/administradores';
import { logRoutes } from '@/app/views/configuracion/logs';
import { feriadosRoutes } from '@/app/views/configuracion/feriados';
import { homeRoutes } from '@/app/views/home';


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Inicio',
      component: TheContainer,
      children: [
        ...homeRoutes,
        ...informesRoutes,
        {
          path:"gestion",
          redirect: "/gestion/clientes",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            ...clientesRoutes,
            ...pedidosRoutes,
            ...pacientesRoutes,
            ...medicosRoutes,
          ]
        },
        {
          path: "configuracion",
          redirect: "/configuracion/usuarios",
          name: "Configuración",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            ...adminRoutes,
            ...logRoutes, 
            ...feriadosRoutes,
          ]
        }
      ]
    },
    ...authRoutes,
    {
      path: "*",
      redirect: "/"
    }
  ]
});

let checkValid = async () =>
await getLoggedUser()
  .then(response => response)
  .catch(e => e);


router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token");
  let user,
    expired = false;

  if (token) {
    let response = await checkValid();

    if (response.status === 'success') {

      const storageLoginTime = localStorage.getItem('login_time');
      // let storageExpiresIn = localStorage.getItem('expires_in');
      // 3600 segundos = 60 minutos = 1 hora

      let now = new Date();

      let dateExpiraSoon = new Date(storageLoginTime);

      let dateExpira = new Date(storageLoginTime);

      dateExpira.setHours(dateExpira.getHours() + 1);
      dateExpiraSoon.setMinutes(dateExpiraSoon.getMinutes() + 40 );

      if ( now >= dateExpiraSoon && now <= dateExpira) {
        // token próximo a caducar
        await refresh();
      }

      // token válido
      user = response.data;

    } else {
      localStorage.removeItem("token");
      token = null;
      expired = true;
    }
  }

  // to.matched.some(record => record.meta.auth
  if (to.meta && to.meta.auth == true) {
    if (token) {
      to.query.user = user;
      next();
    } else {
      next({
        path: "/login",
        query: {
          expired: expired
        }
      });
    }
  } else if (to.meta && to.meta.auth == false) {
    if (token) {
      next({
        path: "/",
        query: { user: user }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
