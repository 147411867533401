<template>
  <div class="animated fadeIn">
    <CModal :show.sync="showModal" size="lg" color="danger">

      <template #header>
        <h5 class="modal-title">¡CUIDADO!</h5>
        <CButtonClose @click="$emit('cancelled',false)" class="text-white"/>
      </template>

      <div class="d-block text-center content-modal">
        <h3>Vas a salir sin guardar los cambios </h3>
        <p>¿Estás seguro que quieres salir de esta página?  </p>
      </div>

       <template #footer>
        <CButton @click="$emit('cancelled',false)" color="outline-warning">Cancelar</CButton>
        <CButton @click="$emit('salir',false)" color="danger">
          <!-- <CIcon name="cil-check-circle" />  -->
          Salir
        </CButton>
      </template>

    </CModal>
  </div>
</template>

<script>

export default {
  name: 'PreguntarCambiosModal',
  props: {
    show: { type: Boolean, default: false, required: true },
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
